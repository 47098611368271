
@import url('https://fonts.googleapis.com/css?family=EB+Garamond&display=swap');

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-container {
  display: flex; 
  flex-grow: 1;
  overflow: auto;
  justify-content: 'center';
}