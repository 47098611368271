.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  text-align: left;
  top: 30%;
  font-size: 3rem;
  font-family: 'EB Garamond';
  color: #454545;
  letter-spacing: 0.5rem;
}

.fade-in-text {
  letter-spacing: 1px;
  font-size: 3rem;
}

.description {
  text-align: justify;
  font-family: 'Calibri Light';
  letter-spacing: 1.5px;
  font-weight: 200;
  font-size: 23.5px;
  color: grey;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .title {
    font-size: 2.3rem; /* Adjust this value as needed */
  }

  .fade-in-text {
    font-size: 2.3rem; /* Adjust this value as needed */
  }

  .description {
    font-size: 1.3rem; /* Adjust this value as needed */
  }
}


/* .fade-in-text {
    font-family: 'EB Garamond';
    font-size: 3rem;
    color: '#454545';
    opacity: 0;
    animation: fadeInOut 4s forwards;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
    

  }
  @media (max-width: 600px) {
    .responsive-text {
      font-size: 1.5rem; 
    }
  } 
  */