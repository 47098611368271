.NavigationBar {
  display: flex;
  justify-content: space-between; /* Changed to space-between */
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.nav-links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.nav-links li {
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-links li a {
  color: black;
  text-align: center;
  padding: 20px 25px; /* Original padding */
  text-decoration: none;
  font-weight: 100;
  font-family: Helvetica, sans-serif;
  font-size: 14px; /* Original font size */
  letter-spacing: 4.5px; /* Original letter spacing */
  display: flex;
  align-items: center;
}

.nav-links li a:hover,
.nav-links li a:focus,
.nav-links li a:active {
  color: grey; 
  background-color: white;
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
  position: absolute;
  right: 20px; /* Positioning the three dots to the right */
  top: 13%;
  /* transform: translateY(-50%); */
}

.hamburger-menu:hover {
  color: grey;
}

/* Dropdown menu styles */
.dropdown-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 0;
  
}

.dropdown-menu.show {
  display: flex;
}

.dropdown-menu li {
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.dropdown-menu li a {
  width: 100%;
  padding: 10px 0;
  color: black;
  text-decoration: none;
  font-weight: 80;
  font-family: Helvetica, sans-serif;
  font-size: 20px; 
  letter-spacing: 5.5px; 

}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li a:active {
  color: grey;
}

/* Close button styles */
.close-button {
  font-size: 20px;
  cursor: pointer;
  color: black;
  position: right;
  top: 1px;
  right: 1px;
}

.close-button:hover {
  color: grey;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
    display: flex;
    flex-wrap: wrap;
  }

  .nav-links li a {
    padding: 10px 15px; /* Reduced padding for mobile */
    font-size: 12px; /* Reduced font size for mobile */
    letter-spacing: 5px; /* Reduced letter spacing for mobile */
    font-family: Helvetica, sans-serif;
  }

  .hamburger-menu {
    display: block;
  }

  .dropdown-menu.show {
    display: flex;
  }
}