@import url('https://fonts.googleapis.com/css?family=EB+Garamond&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes up the full viewport height */
}
.home-container {
    display: flex; /* important defauly majot axis is row */
    justify-content: center; /*major axis */
    align-items: center;
    /* background-color: white; */
    flex-direction: column;
    /* min-height: 100vh; */
    overflow: hidden; 
    width: 100%; 
    height: 100%; 
  }

  
.video-background {
    /* display: flex;  */
    position: absolute;
    top: 50%; /* 50% when used with translate */
    left: 50%; /* 50% when used with translate */
    width: 100%;
    height: 100%;
    /* align-items: top; */
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1; /* Ensure the video is behind other content */
    overflow: hidden; 
    
}

.video-container {
    display: flex; 
    position: relative; 
    /* width: 100%;
    height: 100%; */
    object-fit: cover;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
    object-fit: cover; 

}

.text-container {
    position: absolute; 
    background-color: transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 20px;
    padding-bottom: 20px; 
    padding-left: 35px; 
    padding-right: 12px; /* letter spacing 23px pad the difference between 35 and 23 = 12 at the last letter */ 
    display: flex;
    border: 2px solid white;
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    /* width: 50%;
    max-width: calc(100% - 70px); 
     */
}

.name {
    font-family: "EB Garamond", serif;
    font-size: 33px;
    font-weight: 100; 
    color: white;
    letter-spacing: 23px;
    text-align: center;
    display: inline-block;
    align-self: flex-start; 
    white-space: nowrap; 
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .name {
      white-space: nowrap; 
      font-size: 25px; /* Adjust font size for smaller screens */
      letter-spacing: 9px; /* Adjust letter spacing for smaller screens */
  }
}

.name::after {
    content: attr(data-last-letter);
    position: relative;
    right: 0;
    letter-spacing: 0;
    text-align: center;
    display: inline-block;
  }
  
  .name::last-letter {
    letter-spacing: 0;
  }

  .footer {
    position: relative;
    z-index: 1; /* Ensure footer is above the video */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Add background color to footer */
    color: white;
    padding: 10px;
    width: 100%;
    text-align: center;
  }