/* 

.fade-in-text {
    font-family: 'Roboto';
    font-size: 3rem;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
 */

  .fade-in-text {
    font-family: 'EB Garamond';
    font-size: 3rem;
    color: '#454545';
    animation: fade 5s infinite;
  }
  
  @keyframes fade {
    0% {
      opacity: 0;
      animation-timing-function: ease-in;
    }
    10% {
      opacity: 1;
      animation-timing-function: ease-out;
    }
    90% {
      opacity: 1;
      animation-timing-function: ease-in;
    }
    100% {
      opacity: 0;
      animation-timing-function: ease-out;
    }
  }